import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { TextField } from "clutch/src/TextField/TextField.jsx";

import { OAUTH2_PROVIDERS } from "@/feature-auth/constants/constants.mjs";
import { suggestDomain } from "@/feature-auth/utils/utils.mjs";
import testId from "@/util/test-id.mjs";

export const Form = styled("form")`
  display: flex;
  flex-direction: column;

  width: 360px;

  text-align: center;

  h1 {
    margin-bottom: var(--sp-3);

    color: var(--shade0);
  }

  [role="alert"] {
    display: none;
  }

  .email-warning {
    margin-top: var(--sp-2);
    font-size: var(--sp-3_5);
  }

  .button-group {
    margin-top: var(--sp-4);
    margin-bottom: var(--sp-10);
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin-right: var(--sp-2);

      color: var(--shade3);
    }

    button,
    a {
      padding: 0;

      color: var(--shade1);

      background: none;

      cursor: pointer;
    }
  }
`;

export type OAuth2Provider = { id: string; logo: SVGComponent; color: string };

const OAuth2ButtonGrid = styled("div")`
  display: flex;
  gap: var(--sp-4);

  button:hover {
    filter: brightness(120%);
  }
`;

type AuthenticateFormProps = {
  onSubmit: (email: string) => void;
  startOAuth2Flow: (provider: string) => void;
};

export default function AuthenticateForm({
  onSubmit: onSubmitted,
  startOAuth2Flow,
}: AuthenticateFormProps) {
  const [email, setEmail] = useState<string>("");
  const [warning, setWarning] = useState<React.ReactNode | null>(null);
  const { t } = useTranslation();

  const onChangeEmail = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setEmail(event.currentTarget.value);

      if (event.currentTarget.value.includes("@")) {
        const [, domain] = event.currentTarget.value.split("@");
        const suggestedDomain = suggestDomain(domain);
        if (suggestedDomain) {
          setWarning(
            <div className="flex gap-4 align-center justify-center email-warning">
              <Trans
                i18nKey={"common:suggestedEmailDomain"}
                defaults={"<p>Did you mean <strong>{{domain}}</strong>?</p>"}
                values={{
                  domain: suggestedDomain,
                }}
                components={{
                  p: <p className="shade1" />,
                  strong: <strong className="color-red" />,
                }}
              />
              <Button
                type="button"
                size="medium"
                emphasis="high"
                className="accept"
                onClick={() => {
                  setEmail((prev) => prev.replace(domain, suggestedDomain));
                  setWarning("");
                }}
              >
                {t("common:use", "Use")}
              </Button>
              <Button
                type="button"
                size="medium"
                emphasis="medium"
                className="ignore"
                onClick={() => setWarning("")}
              >
                {t("common:ignore", "Ignore")}
              </Button>
            </div>,
          );
          return;
        }
      }
      setWarning("");
    },
    [setEmail, t],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (e.type !== "submit") return;

      e.preventDefault();

      if (!email) return;

      onSubmitted(email);
    },
    [email, onSubmitted],
  );

  return (
    <Form onSubmit={onSubmit} {...testId("authenticate-form")}>
      <h1 className="type-h5 pad-sp-2">
        {t("common:welcomeToBlitz", "Welcome to Blitz")}
      </h1>
      <TextField
        type="email"
        name="email"
        required
        placeholder={t("common:account.yourEmailAddress", "Your Email Address")}
        onInput={onChangeEmail}
        value={email}
      />
      {warning ? warning : null}
      <ButtonGroup>
        <Button
          type="submit"
          block
          emphasis="high"
          size="large"
          disabled={!email.includes("@") || !email.includes(".") || !!warning}
        >
          {t("common:continue", "Continue")}
        </Button>
      </ButtonGroup>
      <OAuth2ButtonGrid>
        {Object.values(OAUTH2_PROVIDERS).map((provider: OAuth2Provider) => {
          return (
            <Button
              key={provider.id}
              type="button"
              onClick={() => startOAuth2Flow(provider.id)}
              bgColor={provider.color}
              size="large"
              block
            >
              <provider.logo />
            </Button>
          );
        })}
      </OAuth2ButtonGrid>
    </Form>
  );
}

export function meta() {
  return {
    title: ["common:navigation.signIn", "Sign In"],
    description: [],
  };
}
